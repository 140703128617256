import publicApi from "apis/adsMatching"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import {  useHistory, useParams } from "react-router-dom"
import { Container, Row, Col, Navbar, Nav, NavbarBrand, NavbarText } from "reactstrap"


const AdPage = (props) =>{
    const navigate = useHistory()
    const params = useParams()
    const {clickedAd} = useSelector((state)=> state.ads)
    const imageRef = useRef(null)

    useEffect(()=>{
        if(params.adsubpage){
            console.log(params.adsubpage)
        }
        else{
            
        }
        publicApi.incrementAdClick(clickedAd.adId)

        window.addEventListener('resize', handleImageResize)
        handleImageResize();

        return ()=>{
            window.removeEventListener('resize', handleImageResize)
        }

        console.log(clickedAd)
        
    },[])

    const handleImageResize = () =>{
        const image = imageRef.current;
        const screenWidth = window.innerWidth;
        const imageWidth = image.naturalWidth;
        if (imageWidth > screenWidth) {
            image.style.width = '100%';
        } 
        else {
            image.style.width = 'auto';
        }
    }

    return(
        <Container fluid className="p-0 m-0">
            <Navbar style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)'}}>
                <div className="d-flex align-items-md-center"
                    style={window.innerWidth > 576 ? {width: '100%'} : {flexDirection: 'column', width: '100%'}}
                >
                    <NavbarBrand 
                        href={`${clickedAd.webpageLink ? clickedAd.webpageLink : '/' }`}
                        style={{display: "flex", justifyContent: "center", margin: '0'}}
                    ><img href={`${clickedAd.webpageLink ? clickedAd.webpageLink : '/' }`} src={`${clickedAd.logoLink ? clickedAd.logoLink : 'spofi_logo.png'}`} height={"60px"} alt="spofi"/></NavbarBrand>
                    

                    <h1 
                        style={window.innerWidth > 576 ? { position: "absolute", left: "0", marginTop: '10px', width: '100%', textAlign: 'center'} : {textAlign: 'center'}}
                        // style={{}}
                        onClick={()=>{
                            window.location.replace(`${clickedAd.webpageLink ? clickedAd.webpageLink : '/' }`)
                        }}
                    >
                        {clickedAd.name}
                    </h1>
                    
                </div>
                
            </Navbar>
            <Row 
                className="" 
                style={{width: '100%', textAlign: 'center'}}
                onClick={()=>{
                    window.location.replace(`${clickedAd.webpageLink ? clickedAd.webpageLink : '/' }`)
                }}
            >
                <Col>
                    <h3>{clickedAd.adText ? clickedAd.adText : " "}</h3>
                </Col>
                
            </Row>
            <Row>
                <Col>
                    <div style={{height: '100%', overflow: 'hidden', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        <img  
                            src={clickedAd.imageLink} 
                            ref={imageRef}
                            alt="Ad" height={'100%'} 
                            onClick={()=>{
                                window.location.replace(`${clickedAd.webpageLink ? clickedAd.webpageLink : '/' }`)
                            }} />
                            
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AdPage