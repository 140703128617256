import { Button, Col, Row } from "reactstrap";
import styled from "styled-components";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai'
import { useDispatch, useSelector } from "react-redux";
import { googleSearchResult } from "../../redux/searchSlice";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const PaginationPanel = () =>{
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search)
    const navigate = useHistory();

    const {nextPage, previousPage} = useSelector((state)=> state.search);

    const [activePageNumber, setActivePageNumber] = useState(1)

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search)
        const page = decodeURIComponent(params.get('p'))
        setActivePageNumber(parseInt(page))
    },[])

    const goForward = () =>{
        // dispatch(googleSearchResult(nextPage));
        const params = new URLSearchParams(window.location.search)
        const query = decodeURIComponent(params.get('q'))
        window.location.replace(`/search?q=${query}&p=${activePageNumber+1}`)
    }

    const goBack = () =>{
        // dispatch(googleSearchResult(previousPage));
        if(activePageNumber > 1){
            const params = new URLSearchParams(window.location.search)
            const query = decodeURIComponent(params.get('q'))
            window.location.replace(`/search?q=${query}&p=${activePageNumber-1}`)
        }
        
    }

    const numberOfOs = () =>{
        var length = Math.floor(nextPage / 10)
        console.log(length)
        var arr = []
        for(let i=1; i<=length; i++){
            arr.push(i)
        }
        return arr;
    }

    return(
        <Row style={{marginTop: '30px'}}>
            <Col sm="12" md="6" lg="4">
                <Row className="d-flex" >
                    <Col className="d-flex" style={{justifyContent: 'center'}}>
                        { nextPage !== 11 ?
                        <Button className="d-flex" color="transparent" style={{height: '30px'}} onClick={goBack}>
                            <AiOutlineArrowLeft/>
                        </Button> : <Button className="d-flex" color="transparent" style={{height: '30px'}} >&nbsp;</Button>}
                        <img height={'30px'} length={"60px"} src="sp.png" />
                        {
                            numberOfOs().map((num)=>{
                                return <img key={num+"img"} height={'30px'} length={"60px"} src="o.png" alt=" "/>
                            })
                        }
                        
                        <img height={'30px'} length={"60px"} src="fi.png" />
                        <Button className="d-flex" color="transparent" onClick={goForward}>
                            <AiOutlineArrowRight />
                        </Button>
                    </Col>
                </Row>
                <Row className="d-flex">
                    <Col className="d-flex" style={{justifyContent: 'center'}}>
                    {
                            numberOfOs().map((num)=>{
                                if(num === activePageNumber ){
                                    return <Numbers 
                                                key={num+"bold"}
                                            >
                                                <b>{num}</b>
                                            </Numbers>
                                }
                                else{
                                    return <Numbers 
                                                key={num+"simple"}
                                                onClick={()=>{
                                                    const params = new URLSearchParams(window.location.search)
                                                    const query = decodeURIComponent(params.get('q'))
                                                    window.location.replace(`/search?q=${query}&p=${num}`)
                                                }}
                                            >
                                                    {num}
                                            </Numbers>
                                }
                                
                            })
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}

export default PaginationPanel


const Numbers = styled.div.attrs({className: 'num'})`
    margin: 5px;
    font-size: 13px;
    cursor: pointer;
`