
import { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardFooter,
  Spinner,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import useAuth from "hooks/useAuth";
import api from "apis";
import { useSelector } from "react-redux";
import ManageAdsPage from "./ManageAdsPage";
import Dashboard from "./Dashboard";
import styled from "styled-components";
import AddAds from "./AddAds";
import UserManagment from "./UserManagment";

const UnifiedDashboard = (props) => {
  useAuth();

  return (
    <>
      <Header/>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Dashboard/>
        <Spacer />
        <ManageAdsPage/>
        <Spacer />
        <AddAds/>
        <Spacer />
        <UserManagment/>
        <Spacer />
      </Container>
    </>
  );
};

export default UnifiedDashboard;

const Spacer = styled.div.attrs(``)`
    height: 30px;
`
