import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Input, Button, UncontrolledButtonDropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { setSearchQuery } from "../redux/searchSlice";
import { useHistory } from "react-router-dom";
import { useEffect, useMemo } from "react";
import {TbWorld} from 'react-icons/tb'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { setCountryCode } from "redux/settingsSlice";
import SearchFooter from "components/Footers/SearchFooter";



const SearchHome = () =>{
    const navigate = useHistory();
    const dispatch = useDispatch();
    const {searchQuery} = useSelector((state)=> state.search)
    const {countryCode} = useSelector((state)=> state.settings)
    const options = useMemo(() => countryList().getData(), [])

    useEffect(()=>{
        dispatch(setSearchQuery(""))
    },[])

    const searchButtonSubmit = () =>{
        if(searchQuery.trim() !== ""){
            const encodedString = encodeURIComponent(searchQuery)
            navigate.push(`/search?q=${encodedString}&p=1`, {replace: true})
        }
        
    }

    const handleKeyPress = (e) =>{
        if(e.key === "Enter" && searchQuery.trim()){
            searchButtonSubmit();
        }
    }


    return (
        <Container fluid
            style={{overflow: "hidden", position: 'relative'}}
        >
            <Row style={{width: '100dvw', justifyContent: 'flex-end', height: '10dvh'}}>
                <UncontrolledDropdown className="m-1" >
                    <DropdownToggle >
                        <TbWorld />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                            <h6 className="text-overflow m-0">Choose Region!</h6>
                        </DropdownItem>
                        <DropdownItem className="noti-title" header tag="div" onClick={null} style={{color: 'black'}}>
                            <Select 
                                options={options} 
                                value={countryCode} 
                                placeholder={"Select Country"}
                                onChange={(value)=>dispatch(setCountryCode(value))}
                                required={true}
                                validationMessage={"Please select a country!"}
                            />
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Row>
            <Row style={{justifyContent: 'center', alignItems: 'center', height: '85dvh', flexDirection: 'column'}}>
                {/* <Col className="d-flex" style={{justifyContent: 'center', alignItems: 'center', height: '100dvh', flexDirection: 'column'}}> */}
                    <Row>
                        <Col className="d-flex" style={{justifyContent: 'center'}}>
                            <img src="spofi_logo.png"  width={'90%'} alt="spofi logo" />
                        </Col>
                    </Row>
                    <Row style={{justifyContent: 'center', marginTop: '25px', width: '100%'}}>
                        <Col xs="12" sm="12" md="6" lg="4">
                            <Input
                                type="text" 
                                value={searchQuery} 
                                onChange={(e) => dispatch(setSearchQuery(e.target.value))} 
                                onSubmit={searchButtonSubmit}
                                onKeyDown={handleKeyPress}
                                style={{ borderRadius: '10rem'}}
                            />
                        </Col>
                    </Row>
                    <Row style={{justifyContent: 'center', marginTop: '25px', width: '100%',height: '5dvh'}}>
                        <Button style={{minWidth: "140px", width: '10%' }} onClick={searchButtonSubmit}>
                            Spofi Search
                        </Button>
                    </Row>
                {/* </Col> */}
            </Row>
            {/* <SearchFooter /> */}
        </Container>
    );
}

export default SearchHome