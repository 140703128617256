import { useDispatch, useSelector } from 'react-redux'
import { SERVER_API_URL } from 'configs/urls'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { setAuthToken } from 'redux/authSlice'

const useAuth = () =>{
    const navigate = useHistory()
    const dispatch = useDispatch()
    const authToken = useSelector((state)=> state.auth.authToken)

    const [authStatus, setAuthStatus] = useState(false)

    useEffect(()=>{
        axios.get(SERVER_API_URL+'getauth',{
            headers:{
                'AuthToken': authToken
            }
        })
        .then((response)=>{
            // setStatus('Auth\'d')
        })
        .catch((error)=>{
            console.log(error);
            // setStatus('Not Auth\'d')
            dispatch(setAuthToken(''));
            navigate.push('/auth/login', {replace: true})
        })
    },[])

}

export default useAuth