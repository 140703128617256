import { useEffect} from "react"
import SearchResultList from '../../components/SearchPage/SearchResultList'
import { useDispatch, useSelector } from "react-redux"
import { googleSearchResult, setSearchQuery} from "../../redux/searchSlice"

import SearchBar from "../../components/SearchPage/SearchBar"
import PaginationPanel from "../../components/SearchPage/PaginationPanel"
import { Row, Col, Spinner, Container } from "reactstrap"
import '../../styles/SearchPage.css'
import SearchFooter from "components/Footers/SearchFooter"

const SearchPage = () =>{

    const params = new URLSearchParams(window.location.search)

    const dispatch = useDispatch();
    const {nextPage, previousPage, searchList, searchLoading} = useSelector((state)=> state.search);

    useEffect(()=>{
        
        const params = new URLSearchParams(window.location.search)
        const query = decodeURIComponent(params.get('q'))
        const page = decodeURIComponent(params.get('p'))
        const pageNumberStartQueryIndex = ((parseInt(page) - 1) * 10) + 1
        dispatch(setSearchQuery(query))
        dispatch(googleSearchResult(pageNumberStartQueryIndex));
    },[])


    return (
        <Container fluid>
            <SearchBar />
            {searchLoading ? <Row><Col xs="12" className="d-flex" style={{justifyContent: 'center', alignItems: 'center', height: '90vh'}}><Spinner/></Col></Row> :
                <SearchResultList resultList={searchList}/>
            }
            <hr/>
            <PaginationPanel />
            {/* <SearchFooter /> */}
        </Container>
    )
}

export default SearchPage