import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"



const EmailVerifiedScreen = () =>{
    const navigate = useHistory()

    useEffect(()=>{
        setTimeout(()=> navigate.push('/auth/login', {replace: true}), 6000)
        
    },[])

    return(
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardBody style={{justifyContent: 'center'}}>
                    <div style={{textAlign: 'center'}}>
                        Your Email is Verified!
                    </div>

                </CardBody>
            </Card>
        </Col>
    )
}

export default EmailVerifiedScreen