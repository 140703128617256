
// reactstrap components
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import {getAuth, confirmPasswordReset, verifyPasswordResetCode} from 'firebase/auth';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "redux/authSlice";
import {toast} from 'react-toastify';
import superApi from "apis/superadminApi";
import EmailVerifiedScreen from "./EmailVerified";
import { applyActionCode} from "firebase/auth";

const ResetPassword = () => {

  const navigate = useHistory()
  const dispatch = useDispatch()

  const params = useParams();
  const location = useLocation()

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [loading, setLoading] = useState(false)

  const [resetPasswordMode, setResetPasswordMode] = useState(false)
  const [verifyEmailMode, setVerifyEmailMode] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(()=>{
    setPageLoading(true)
    const mode = new URLSearchParams(location.search).get("mode");
    if(mode === "resetPassword"){
      setResetPasswordMode(true)
      setVerifyEmailMode(false)
      setPageLoading(false)
    }
    if(mode === "verifyEmail"){
      setResetPasswordMode(false)
      verifyEmailOobCode();
      // setVerifyEmailMode(true)
      // setPageLoading(false)
    }
    
    console.log("mode: ",mode)
  },[])

  const verifyEmailOobCode = async() =>{
    const auth = getAuth()
    const actionCode = new URLSearchParams(location.search).get("oobCode");
    const apiKey =  new URLSearchParams(location.search).get("apiKey");
    applyActionCode(auth,actionCode)
    .then((res)=>{
      console.log(res)
      setVerifyEmailMode(true)
      setPageLoading(false)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  const resetPassword = async() =>{
    console.log("params",params)
    const oobCode = new URLSearchParams(location.search).get("oobCode");
    setLoading(true)
    const auth = getAuth();
    if(validatePassword()){
        confirmPasswordReset(auth,oobCode,password.trim())
        .then((res)=>{
            console.log(res)
            toast.success('Password Reset!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                setLoading(false)
                setTimeout(()=> navigate.push('/auth/login'), 4000)
        })
        .catch((err)=>{
            toast.error('Password Not reset!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                setLoading(false)
        })
        ;
        
    }
    else{
        setLoading(false)
    }
    

  }

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/;

      if (password.trim() === '') {
          // setPasswordError('Password is required');
          toast.error('Password Required!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          return false
      } else {
        if(!passwordRegex.test(password.trim())){
          toast.error('Password should be greater than 8 characters, alphanumeric, must contain special charcters and must have upper and lowercase letter!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          return false
        }
        else{
          if(password !== confirmPassword){
            toast.error('Password and Confirm Password should be same!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            return false
          }
          else{
            return true
          }
          
        }
      }
  };

  const handleKeyPress = (e) =>{
    if(e.key === "Enter"){
        resetPassword();
    }
  }

  return (
    
    <>{
      resetPasswordMode ? 
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Reset Password</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e)=>setPassword(e.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                  </InputGroup>
                  
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="new-password"
                      value={confirmPassword}
                      onChange={(e)=>setConfirmPassword(e.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                  </InputGroup>
                  
                </FormGroup>
                <div className="text-center">
                  <Button disabled={loading} className="my-4" color="primary" type="button" onClick={resetPassword}>
                    {loading ? <Spinner size="sm"/> : "Reset Password"}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      : verifyEmailMode ? 
        <EmailVerifiedScreen /> : <Spinner />
    }
      
    </>
  );
};

export default ResetPassword;
