import superApi from "apis/superadminApi"
import OnlyHeader from "components/Headers/OnlyHeader"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Card, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Label, Row, Spinner, Modal, Table } from "reactstrap"
import Select from 'react-select'
import countryList from 'react-select-country-list'
import {IoTrashBinSharp} from 'react-icons/io5'
import {FaPencilAlt} from 'react-icons/fa'
import { toast } from "react-toastify"
import useAuth from "hooks/useAuth"
import api from "apis"


const AddAdmins = () =>{
    useAuth();

    const options = useMemo(() => countryList().getData(), [])
    const {authToken} = useSelector((state)=> state.auth)
    const [users, setUsers] = useState([])
    const [usersLoading, setUsersLoading] = useState(true)

    const [modalOpen, setModalOpen] = useState(false)
    const [deleteUser, setDeleteUser] = useState({})
    const [deleteLoading, setDeleteLoading] = useState(false)

    const [editUserModal, setEditUserModal] = useState(false)
    const [userDetails, setUserDetails] = useState({
        houseNumber: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        name: ''
    })
    const [nameInvalid, setnameInvalid ] = useState(false)
    const [houseNumberInvalid, sethouseNumberInvalid ] = useState(false)
    const [streetInvalid, setstreetInvalid ] = useState(false)
    const [zipInvalid, setzipInvalid ] = useState(false)
    const [cityInvalid, setcityInvalid ] = useState(false)
    const [countryInvalid, setcountryInvalid ] = useState(false)
    const [editUserLoading, setEditUserLoading] = useState(false)

    useEffect(()=>{
        getAllUsers();
    },[modalOpen, editUserModal])

    const getAllUsers = async() =>{
        superApi.getAllUsers(authToken)
        .then((response)=>{
            setUsers(response.data.users)
            setUsersLoading(false)
        })
        .catch((err)=>{
            setUsersLoading(false)
        })
    }

    const toggleDeleteModal = () =>{
        setModalOpen(!modalOpen)
    }

    const clearDeleteModal = () =>{
        setDeleteUser({ 
        })
        setModalOpen(false)
    }

    const handleDeleteUser = async() =>{
        setDeleteLoading(true)
        superApi.deleteUserById(deleteUser.userId,authToken)
        .then((response)=>{
            toast.success('User Deleted!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setDeleteLoading(false)
            clearDeleteModal()
        })
        .catch((err)=>{
            console.log(err)
            toast.error('Unable to delete User!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setDeleteLoading(false)
            clearDeleteModal()
        })
    }

    const toggleEditUserModal = () =>{
        setEditUserModal(!editUserLoading)
    }

    const clearEditUserModal = () =>{
        setUserDetails({houseNumber: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        name: ''})
        setEditUserModal(false)
        setnameInvalid(false) 
        sethouseNumberInvalid(false) 
        setstreetInvalid(false) 
        setzipInvalid(false) 
        setcityInvalid(false) 
        setcountryInvalid(false) 
        setEditUserLoading(false)
    }

    const handleEditUser = async() =>{
        if(validateUserDetails()){
            setEditUserLoading(true)
            superApi.editUser({user: userDetails}, authToken)
            .then((res)=>{
                toast.success('User Details Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setEditUserLoading(false)
                clearEditUserModal()
            })
            .catch((err)=>{
                toast.error('Unable to edit User Details Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setEditUserLoading(false)
                clearEditUserModal()
            })
        }
    }

    const validateUserDetails = () =>{
        console.log("Validate called")
        var invalidFlag = false
        if(userDetails.name.trim() === ''){
          setnameInvalid(true)
          invalidFlag = true
        }
        else{
          setnameInvalid(false)
        }
        if(userDetails.houseNumber.trim() === ''){
          sethouseNumberInvalid(true)
          invalidFlag = true
        }
        else{
          sethouseNumberInvalid(false)
        }
        if(userDetails.street.trim() === ''){
          setstreetInvalid(true)
          invalidFlag = true
        }
        else{
          setstreetInvalid(false)
        }
        if(userDetails.zip.trim() === ''){
          setzipInvalid(true)
          invalidFlag = true
        }
        else{
          setzipInvalid(false)
        }
        if(userDetails.city.trim() === ''){
          setcityInvalid(true)
          invalidFlag = true
        }
        else{
          setcityInvalid(false)
        }
        if(userDetails.country === ''){
          setcountryInvalid(true)
          invalidFlag = true
        }
        else{
          setcountryInvalid(false)
        }
        if(invalidFlag === true){
          return false
        }
        else{
          return true
        }
    }

    return(
        <>
            <OnlyHeader />
            <Container className="mt--7" fluid>
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader><h3>Manage Admins</h3></CardHeader>
                            {
                                usersLoading ? <Row className="d-flex" style={{justifyContent: 'center'}}><Spinner /></Row> :
                                users.length === 0 ? <Row className="d-flex" style={{justifyContent: 'center'}}><h2>No Data!</h2></Row> :
                                <Table responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Email</th>
                                            {/* <th scope="col"># of Ads</th> */}
                                            <th scope="col">Role</th>
                                            <th scope="col">Created</th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user)=>{
                                            if(user.role !== "super-admin"){
                                                return (
                                                    <tr key={user.userId}>
                                                        <td>{user.email}</td>
                                                        <td>{user.role}</td>
                                                        <td>{user.userCreated}</td>
                                                        <td>
                                                            <Button
                                                                style={{padding: '0px', boxShadow: 'none', backgroundColor:'transparent'}}
                                                                onClick={(()=>{
                                                                    setUserDetails({...userDetails, ...user});
                                                                    toggleEditUserModal();
                                                                })}

                                                            >
                                                                <FaPencilAlt />
                                                            </Button>
                                                            <Button 
                                                                style={{padding: '0px', boxShadow: 'none', backgroundColor:'transparent'}}
                                                                onClick={()=>{
                                                                    setDeleteUser(user)
                                                                    toggleDeleteModal();
                                                                }}
                                                            >
                                                                <IoTrashBinSharp 
                                                                    color="red"
                                                                    size={'20px'}
                                                                />
                                                            </Button>
                                                            
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            
                                        })}
                                    </tbody>
                                </Table>
                            }
                        </Card>
                    </Col>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    toggle={toggleDeleteModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Delete User {deleteUser.email}?</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={clearDeleteModal}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col className="d-flex" style={{justifyContent: "space-around"}}>
                                <Button
                                    color="primary"
                                    onClick={handleDeleteUser}
                                    disabled={deleteLoading}
                                >
                                    YES
                                </Button>
                                {deleteLoading && <Spinner size="sm"/>}
                                <Button
                                    color="primary"
                                    onClick={clearDeleteModal}
                                    disabled={deleteLoading}
                                >
                                    NO
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                
                <Modal
                    className="modal-dialog-centered"
                    isOpen={editUserModal}
                    toggle={toggleEditUserModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Edit User {userDetails.email}</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={clearEditUserModal}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    <FormGroup>
                                        <Label>Company Name</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.name}
                                            onChange={(e)=> setUserDetails({...userDetails, name: e.target.value})}    
                                            invalid={nameInvalid}
                                            required={true}
                                        />
                                        <FormFeedback>Name is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>House Number</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.houseNumber}
                                            onChange={(e)=> setUserDetails({...userDetails, houseNumber: e.target.value})} 
                                            invalid={houseNumberInvalid}
                                        />
                                        <FormFeedback>Street is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Street</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.street}
                                            onChange={(e)=> setUserDetails({...userDetails, street: e.target.value})} 
                                            invalid={streetInvalid}
                                        />
                                        <FormFeedback>Street is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>City Name</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.city}
                                            onChange={(e)=> setUserDetails({...userDetails, city: e.target.value})} 
                                            invalid={cityInvalid}
                                        />
                                        <FormFeedback>City is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Post Code</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.zip}
                                            onChange={(e)=> setUserDetails({...userDetails, zip: e.target.value})} 
                                            invalid={zipInvalid}
                                        />
                                        <FormFeedback>Post Code is required!</FormFeedback>
                                    </FormGroup>

                                    <FormGroup>
                                        <Select
                                            options={options} 
                                            value={userDetails.country} 
                                            placeholder={"Select Country"}
                                            onChange={(value)=>setUserDetails({...userDetails, country: value})}
                                            required={true}
                                            validationMessage={"Please select a country!"}
                                        />
                                        {countryInvalid && <div style={{color: 'red'}}>Please Select a country!</div>}
                                    </FormGroup>
                                    <div className="d-flex justify-content-center" >
                                        <Button
                                          color="primary"
                                          disabled={editUserLoading}
                                          onClick={handleEditUser}
                                        >
                                            {editUserLoading ? <Spinner size="sm"/> : "Save"}
                                        </Button>
                                    </div>
                    </div>
                </Modal>
            </Container>
        </>
    )
}

export default AddAdmins