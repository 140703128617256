import {Row, Col} from 'reactstrap'



const AboutPage = () =>{
    return (
        <Row>
            <Col>
                <h1>About Page</h1>
            </Col>
        </Row>
    )
}

export default AboutPage