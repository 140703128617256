/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Index from 'views/Index';
import Profile from 'views/Profile.js';
import Maps from 'views/Maps.js';
import Register from 'views/auth/Register.js';
import Login from 'views/auth/Login.js';
import Tables from 'views/Tables.js';
import Icons from 'views/Icons.js';
import AddAdmins from 'views/SuperAdmin/AddAdmins';
import ForgotPassword from 'views/auth/ForgotPassword';
import ManageAdsPage from 'views/Admin/ManageAdsPage';
import ManageDashboards from 'views/SuperAdmin/ManageDashboards';
import ResetPassword from 'views/auth/resetPassword';
import ManageAds from 'views/SuperAdmin/ManageAds';
import AskEmailVerification from 'views/auth/AskEmailVerification';
import UserManagment from 'views/Admin/UserManagment';
import Dashboard from 'views/Admin/Dashboard';
import AddAds from 'views/Admin/AddAds';
import UnifiedDashboard from 'views/Admin/UnifiedDashboard';

var routes = [
	{
		path: '/createadmins',
		name: 'Manage Admins',
		icon: 'ni ni-circle-08 text-red',
		component: AddAdmins,
		layout: '/superadmin',
	},
	{
		path: '/managedashboards',
		name: 'Manage Dashboards',
		icon: 'ni ni-spaceship text-purple',
		component: ManageDashboards,
		layout: '/superadmin',
	},
	{
		path: '/manageads',
		name: 'Manage Ads',
		icon: 'ni ni-folder-17 text-orange',
		component: ManageAds,
		layout: '/superadmin',
	},
	// {
	// 	path: '/index',
	// 	name: 'Dashboard',
	// 	icon: 'ni ni-tv-2 text-primary',
	// 	component: Dashboard,
	// 	layout: '/admin',
	// },
	// {
	// 	path: '/icons',
	// 	name: 'Icons',
	// 	icon: 'ni ni-planet text-blue',
	// 	component: Icons,
	// 	layout: '/admin',
	// },
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'ni ni-tv-2 text-primary',
		component: UnifiedDashboard,
		layout: '/admin',
	},
	// {
	// 	path: '/ads',
	// 	name: 'Ads',
	// 	icon: 'ni ni-single-copy-04 text-red',
	// 	component: AddAds,
	// 	layout: '/admin',
	// },
	// {
	// 	path: '/adspage',
	// 	name: 'Manage Ads Page',
	// 	icon: 'ni ni-single-copy-04 text-red',
	// 	component: ManageAdsPage,
	// 	layout: '/admin',
	// },
	// {
	// 	path: '/usermanagment',
	// 	name: 'User Managment Page',
	// 	icon: 'ni ni-single-02 text-green',
	// 	component: UserManagment,
	// 	layout: '/admin',
	// },
	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25 text-info',
		component: Login,
		layout: '/auth',
		isMenu: false,
	},
	{
		path: '/register',
		name: 'Register',
		icon: 'ni ni-circle-08 text-pink',
		component: Register,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/forgotpassword',
		name: 'ForgotPassword',
		icon: 'ni ni-circle-08 text-pink',
		component: ForgotPassword,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/resetpassword',
		name: 'ResetPassword',
		icon: 'ni ni-circle-08 text-pink',
		component: ResetPassword,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/verifyemail',
		name: 'VerifyEmail',
		icon: 'ni ni-circle-08 text-pink',
		component: AskEmailVerification,
		layout: '/auth',
		isMenu: false
	}
];
export default routes;
