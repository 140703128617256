import axios from 'axios'

import { SERVER_API_URL } from 'configs/urls'




const axiosObj = axios.create({
    baseURL: SERVER_API_URL,
})

const createUser = (payload, authToken) => axiosObj.post(`/createuser`, payload,  {headers: {"AuthToken": authToken}})
const userExists = (authToken) => axiosObj.get(`/userexists`, {headers: {"AuthToken": authToken}})
const userData = (authToken, payload='') => axiosObj.get(`/userdata`,{headers: {"AuthToken": authToken}, params:{adminId: payload}})
const editUser = (payload, authToken, adminId='') => axiosObj.post(`/edituser`, payload, {headers: {"AuthToken": authToken}, params:{adminId: adminId}})

const adSubpageUnique = (payload, authToken, adminId='') => axiosObj.post(`/adsubpageunique`, payload, {headers: {"AuthToken": authToken}, params:{adminId: adminId}})

const createAd = (payload, authToken, adminId='') => axiosObj.post(`/createad`, payload, {headers: {"AuthToken": authToken}, params:{adminId: adminId}})
const getAds = (authToken, payload='') => axiosObj.get(`/getspofiads`, {headers: {"AuthToken": authToken}, params:{adminId: payload}})
const deleteAd = (payload,authToken) => axiosObj.delete(`/deletead`, {headers: {"AuthToken": authToken}, params:{adId: payload}})
const editAd = (payload, authToken) => axiosObj.post(`/editad`, payload,{headers: {"AuthToken": authToken} })

const getMonthlyData = (authToken) => axiosObj.get(`/monthlydata`, {headers: {"AuthToken": authToken}})
const get6Months = (authToken, payload='') => axiosObj.get(`/get6months`, {headers: {"AuthToken": authToken}, params:{adminId: payload}})
const getMetrics = (authToken, payload='') => axiosObj.get(`/metrics`, {headers: {"AuthToken": authToken}, params:{adminId: payload}})

const api = {
    createUser,
    userExists,
    userData,
    editUser,

    adSubpageUnique,

    createAd,
    getAds,
    deleteAd,
    editAd,
    
    getMonthlyData,
    get6Months,
    getMetrics
}

export default api