
// reactstrap components
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "redux/authSlice";
import {toast} from 'react-toastify';
import superApi from "apis/superadminApi";
import { setAuthEmail } from "redux/authSlice";

const Login = () => {

  const navigate = useHistory()
  const dispatch = useDispatch()

  // const []
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false)

  const signInWithEmailPass = async() =>{
    setLoading(true)
    const auth = getAuth();
    signInWithEmailAndPassword(auth,email.trim(),password.trim())
        .then(async(res)=>{
            let token = await res.user.getIdToken(true)
            console.log("Email: ",res.user.email)
            dispatch(setAuthEmail(res.user.email))
            await saveToken()
            console.log("EMAIL VERIFIED: ", res.user.emailVerified)
            if(!res.user.emailVerified){
              navigate.push('/auth/verifyemail')
            }
            else{
              superApi.getSuperAdminAuth(token)
              .then(()=> {
                console.log("Xx_SUPERADMIN_xX")
                navigate.push('/superadmin/index', {replace: true})
                setLoading(false)
              })
              .catch((e)=>{
                // if(e.response.status === 401){
                  navigate.push('/admin/index', {replace: true})
                // }
                setLoading(false)
              })
            }
        })
        .catch((err)=>{
            console.log("Wrong Email or Password!")
            // setAuthResponse('FAILED')
            toast.error('Wrong Email or Password!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            console.log(err)
            setLoading(false)
        })
  }
  const saveToken = async() =>{
    const auth = getAuth();
    var authToken = await auth.currentUser.getIdToken(true);
    dispatch(setAuthToken(authToken))
  }

  const handleKeyPress = (e) =>{
    if(e.key === "Enter"){
      signInWithEmailPass();
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button disabled={loading} className="my-4" color="primary" type="button" onClick={signInWithEmailPass}>
                  {loading ? <Spinner size="sm"/> : "Sign in"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/auth/forgotpassword"
              // onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
              // onClick={(e) => navigate.push('/auth/register',{replace: true})}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
