import { useHistory } from "react-router-dom"

const { Row, Col, Container, Button, Navbar } = require("reactstrap")


const BusinessPage = () =>{
    const navigate = useHistory()
    return(
        <Container fluid>
            <Row style={{justifyContent: 'flex-end'}}>
                <Button
                    color="primary"
                    onClick={()=>navigate.push('/auth/login')}
                    style={{margin: '10px'}}
                >
                    Sign in
                </Button>
            </Row>
            <Row>
                <Col>
                    <h1>Business Page</h1>
                </Col>
            </Row>
        </Container>
        
    )
}

export default BusinessPage