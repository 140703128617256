const { createSlice } = require("@reduxjs/toolkit");

const adsSlice = createSlice({
    name: "ads",
    initialState: {
        clickedAd: {
            name: '',
            imageLink: ''
        }
    },
    reducers:{
        setClickedAd: (state, action) =>{
            state.clickedAd = action.payload
        }
    }
})

export const {setClickedAd} = adsSlice.actions
export default adsSlice.reducer