import { createSlice } from "@reduxjs/toolkit";
import googleSearch from "../apis/googleSearch";
const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchQuery: '',
        nextPage: 1,
        previousPage: 1,
        searchList: [],
        searchLoading: false
    },
    reducers: {
        setSearchQuery: (state, action)=>{
            state.searchQuery = action.payload
        },
        setNextPage: (state, action)=>{
            state.nextPage = action.payload
        },
        setPreviousPage: (state, action)=>{
            state.previousPage = action.payload
        },
        setSearchList: (state, action) =>{
            state.searchList = action.payload
        },
        setSearchLoading: (state, action) =>{
            state.searchLoading = action.payload
        }
    }
})

export const {
    setSearchQuery,
    setNextPage,
    setPreviousPage,
    setSearchList,
    setSearchLoading,
} = searchSlice.actions

export default searchSlice.reducer


export const googleSearchResult = (startIndex) => async(dispatch, getState) =>{
    dispatch(setSearchLoading(true))
    var q = getState().search.searchQuery
    var countryCode = getState().settings.countryCode.value
    if(q){
        var res = await googleSearch(q, startIndex, countryCode);
        dispatch(setSearchList(res.items))
        if(res.queries.nextPage){
            dispatch(setNextPage(res.queries.nextPage[0].startIndex))
        }
        if(res.queries.previousPage){
            dispatch(setPreviousPage(res.queries.previousPage[0].startIndex))
        }
    }
    dispatch(setSearchLoading(false))
}