import OnlyHeader from "components/Headers/OnlyHeader";
import { useReducer, useState } from "react";
import { FormText, Spinner, Button, Card, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, FormFeedback, CardFooter, UncontrolledTooltip, Alert } from "reactstrap";
import { storage } from "configs/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { getAuth } from "firebase/auth";
import api from "apis";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import {FaPencilAlt} from 'react-icons/fa'
import {FcInfo} from 'react-icons/fc'
import {MdDeleteForever} from 'react-icons/md'
import { toast } from "react-toastify";


const AddAds = (props) =>{
    useAuth();
    const {authToken} = useSelector((state)=> state.auth)
    
    
    const [createAdModal, setCreateAdModal] = useState(false);
    const [uploadImageText, setUploadImageText] = useState('')

    const [createAdLoading, setCreateAdLoading] = useState(false)
    const [adsLoading, setAdsLoading] = useState(true)
    const [ads, setAds] = useState([])

    const [ad, setAd] = useState({
        name: '',
        keyword: '',
        imageLink: '',
        adText: '',
        clickLimit: -1,
    })

    const [user,setUser] = useState({
        adRate: 0
    })
    const [adAgreement, setAdAgreement] = useState(false)
    
    const [nameInvalid, setNameInvalid] = useState(false)
    const [keywordInvalid, setKeywordInvalid] = useState(false)
    const [imageInvalid, setImageInvalid] = useState(false)
    const [adTextInvalid, setAdTextInvalid] = useState(false)
    const [adAgreementInvalid, setAdAgreementInvalid] = useState(false)

    const [editAdModal, setEditAdModal] = useState(false)
    const [deleteAdModal, setDeleteAdModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteAd, setDeleteAd] = useState({})

    const [editAd, setEditAd] = useState({
        name: '',
        adText: '',
        keyword: '',
        imageLink: '',
        clickLimit: -1,
    })

    useEffect(()=>{

        var propsAdminId = ''
        if(props.adminId){
        propsAdminId = props.adminId
        }

        getAdsForAdmin();
        api.userData(authToken, propsAdminId)
        .then((response)=>{
            setUser({
                ...user,
                ...response.data.user
            })
        })

    },[createAdModal,editAdModal,deleteAdModal, props.adminId])

    const openEditAdModal = (ad) =>{
        console.log(ad)
        setEditAd({
            ...editAd,
            ... ad,
            keyword: ad.keyword.join(' ')
        })
        setEditAdModal(true)
    }

    const toggleEditAdModal = () =>{
        setEditAd({
            name: '',
            adText: '',
            keyword: '',
            imageLink: ''
        })
        setEditAdModal(!editAdModal)
    }

    const validatorCheckEdit = () =>{
        if(editAd.name.trim() === ''){
            setNameInvalid(true)
        }
        if(editAd.keyword.trim() === ''){
            setKeywordInvalid(true)
        }
        if(editAd.uploadImageText === 'done' ){
            setImageInvalid(false)
        }
        else{
            setImageInvalid(true)
        }

        if(uploadImageText === 'done' || editAd.name.trim() !== '' || editAd.keyword.trim() !== ''){
            return true
        }
        else{
            return false
        }
    }

    const handleEditAdImageUpload = (e) =>{
        setUploadImageText('uploading')
        if(e.target.files[0]){
            var adminId = ''
            if(props.adminId){
                adminId = props.adminId
            }
            else{
                adminId = getAuth().currentUser.uid
            }
            const storageRef = ref(storage, `ads/${adminId}/${e.target.files[0].name}`)
            const uploadTask = uploadBytesResumable(storageRef, e.target.files[0])

            uploadTask.catch((err)=> setUploadImageText("Error in Upload!"))
            uploadTask.then(()=>{
                setUploadImageText('done')
                getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
                    console.log(url)
                    setEditAd({
                        ...editAd,
                        imageLink: url,
                    })
                })
            })
        }
        else{
            setUploadImageText('Please Choose a file!')
        }
    }

    const handleEditAdSubmit = () =>{
        setLoading(true)
        if(validatorCheckEdit()){
            api.editAd({ad: editAd}, authToken)
            .then((res)=>{
                toast.success('Ad Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                setEditAdModal(false)
            })
            .catch((err)=>{
                toast.error('Ad Update Error!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                setEditAdModal(false)
            })
        }
        else{
            setLoading(false)
        }
        
    }

    const toggleDeleteAdModal = () =>{
        setDeleteAdModal(!deleteAdModal)
    }

    const openDeleteAdModal = (ad) =>{
        setDeleteAd(ad)
        setDeleteAdModal(true)
    }

    const handleDeleteAd = (adId) =>{
        setLoading(true)
        api.deleteAd(adId, authToken)
        .then((resp)=>{
            toast.success('Ad Deleted!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            setDeleteAdModal(false)
        })
        .catch((err)=>{
            toast.error('Ad Delete Error!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            setDeleteAdModal(false)

        })
    }

    

    const toggleAdModal = () =>{
        setCreateAdModal(!createAdModal)
    }

    const clearAdFields = () =>{
        setAd({
            name: '',
            keyword: '',
            imageLink: '',
        })
    }

    const validatorCheck = () =>{
        console.log(`ad name is ${ad.name.trim()} is ${ad.name.trim() === ''}`)
        if(ad.name.trim() === ''){
            setNameInvalid(true)
        }
        if(ad.keyword.trim() === ''){
            setKeywordInvalid(true)
        }
        if(ad.uploadImageText === 'done' ){
            setImageInvalid(false)
        }
        else{
            setImageInvalid(true)
        }

        if(adAgreement === false){
            setAdAgreementInvalid(true)
        }
        else{
            setAdAgreementInvalid(false)
        }   
        if(uploadImageText === 'done' && ad.name.trim() !== '' && ad.keyword.trim() !== '' && adAgreement === true){
            return true
        }
        else{
            return false
        }
    }

    const handleImageUpload = (e) =>{
        setUploadImageText('uploading')
        if(e.target.files[0]){
            var adminId = ''
            if(props.adminId){
                adminId = props.adminId
            }
            else{
                adminId = getAuth().currentUser.uid
            }
            const storageRef = ref(storage, `ads/${adminId}/${e.target.files[0].name}`)
            const uploadTask = uploadBytesResumable(storageRef, e.target.files[0])

            uploadTask.catch((err)=> setUploadImageText("Error in Upload!"))
            uploadTask.then(()=>{
                setUploadImageText('done')
                getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
                    console.log(url)
                    setAd({
                        ...ad,
                        imageLink: url,
                    })
                })
            })
        }
        else{
            setUploadImageText('Please Choose a file!')
        }
    }

    const submitCreateNewAd = async() =>{
        setCreateAdLoading(true)
        var propsAdminId = ''
        if(props.adminId){
            propsAdminId = props.adminId
        }
        if(validatorCheck()){
            api.createAd({
                name: ad.name,
                keyword: ad.keyword.toLowerCase(),
                imageLink: ad.imageLink,
                adText: ad.adText,
                clickLimit: Number(ad.clickLimit),
            }, authToken, propsAdminId).then((res)=>{
                console.log(res)
                setCreateAdLoading(false);
                setCreateAdModal(false)
                clearAdFields();
            })
            .catch(err=> {
                setCreateAdLoading(false);
                setCreateAdModal(false)
                console.log(err)
            })
        }
        else{
            setCreateAdLoading(false)
        }
    }

    const getAdsForAdmin = () =>{
        var propsAdminId = ''
        if(props.adminId){
            propsAdminId = props.adminId
        }
        api.getAds(authToken, propsAdminId)
        .then((response)=>{
            console.log(response)
            setAds(response.data.Ads)
            setAdsLoading(false)
            
        })
        .catch((err)=>{
            setAdsLoading(false)
        })

    }

    return(
        <>
            {/* <OnlyHeader />
            <Container className="mt--7" fluid> */}
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader>
                                <h3>Ads</h3>
                            </CardHeader>
                            <Row style={{justifyContent: 'flex-end'}}>
                                <Button className="m-4" onClick={toggleAdModal}>
                                    New Ad
                                </Button>
                            </Row>
                            {
                                adsLoading ? <Row className="d-flex" style={{justifyContent: 'center'}}><Spinner /></Row> :
                                ads.length === 0 ? <Row className="d-flex" style={{justifyContent: 'center'}}><h2>No Data!</h2></Row> :
                                <Table responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Headline</th>
                                        <th scope="col">Keywords</th>
                                        <th scope="col">Clicks</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {ads.map((ad, index)=>{
                                        return (
                                            <tr key={`${index+ad.adId}`}>
                                                <td>{ad.name}</td>
                                                <td
                                                    style={{maxWidth: '25dvw', overflow: "auto", maxHeight: '400px'}}
                                                >
                                                    <div>
                                                        {ad.keyword.map((key)=> key+" ")}
                                                    </div>
                                                </td>
                                                <td>{ad.clicks}</td>
                                                <td>
                                                    <Button
                                                        className="btn-icon btn-2"
                                                        color="primary"
                                                        type="button"
                                                        style={{padding: '3px 5px 3px 5px'}}
                                                        onClick={()=> openEditAdModal(ad)}
                                                    >
                                                        <FaPencilAlt />
                                                    </Button>
                                                    <Button
                                                        className="btn-icon btn-2"
                                                        color="red"
                                                        type="button"
                                                        style={{backgroundColor: '#F44336', padding: '3px 5px 3px 5px'}}
                                                        onClick = {()=> openDeleteAdModal(ad)}
                                                    >
                                                        <MdDeleteForever color="white"/>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                </Table>
                            }
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={createAdModal}
                    toggle={toggleAdModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Create a New Ad</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setCreateAdModal(false)}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <FormGroup>
                                <Label>
                                    Headline
                                </Label>
                                <Input 
                                    type="text" 
                                    invalid={nameInvalid}
                                    value={ad.name}
                                    onChange={(e)=> setAd({...ad, name: e.target.value})}
                                />
                                <FormFeedback>
                                    Name required!
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Keyword
                                    <span 
                                        className="m-1"
                                        id="keyword-tooltip"
                                    ><FcInfo /></span>
                                </Label>
                                <Input 
                                    type="text"
                                    invalid={keywordInvalid}
                                    value={ad.keyword}
                                    onChange={(e)=> setAd({...ad, keyword: e.target.value})}
                                />
                                <FormFeedback>
                                    Keywords are required!
                                </FormFeedback>
                                <UncontrolledTooltip
                                    placement="right"
                                    target="keyword-tooltip"
                                >
                                    Multiple Keywords can be added. Space seprated.
                                </UncontrolledTooltip>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Ad Text
                                </Label>
                                <Input 
                                    type="text"
                                    // invalid={keywordInvalid}
                                    value={ad.adText}
                                    onChange={(e)=> setAd({...ad, adText: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Clicks Limit
                                    <span 
                                        className="m-1"
                                        id="click-limit-tooltip"
                                    ><FcInfo /></span>
                                </Label>
                                <Input 
                                    type={"number"}
                                    value={ad.clickLimit}
                                    onChange={(e)=> setAd({... ad, clickLimit: e.target.value})}
                                />
                                <UncontrolledTooltip
                                    placement="right"
                                    target="click-limit-tooltip"
                                >
                                    Amount of Clicks that ad is shown. <br/> -1 is for unlimited.
                                </UncontrolledTooltip>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Choose an image for Ad
                                </Label>
                                <Input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleImageUpload}
                                    invalid={imageInvalid}
                                />
                                <FormFeedback>Please Select an image file</FormFeedback>
                                <FormText>{uploadImageText === 'uploading' ? <Spinner size={"sm"}/> : uploadImageText === 'done' ? "Upload Done." : ""}</FormText>
                                <FormFeedback>Please Select an image file</FormFeedback>

                            </FormGroup>
                            <FormGroup>
                                    <Input 
                                        type="checkbox" 
                                        checked={adAgreement}
                                        onChange={(e)=> {
                                            setAdAgreement(!adAgreement)
                                        }}
                                        invalid={adAgreementInvalid}
                                    />
                                    {' '}
                                    <Label check>
                                        {`By checking this box you agree to the PPC(Price Per Ad Click) of $${user.adRate}`}
                                    </Label>
                                    
                                    <FormFeedback>You need to check this box to create ad</FormFeedback>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <Button 
                            color="primary"
                            onClick={submitCreateNewAd}>
                            {createAdLoading ? <Spinner size="xs" /> : "Create Ad"}
                        </Button>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={deleteAdModal}
                    toggle={toggleDeleteAdModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Delete Ad {deleteAd.name}?</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleDeleteAdModal}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col className="d-flex" style={{justifyContent: "space-around"}}>
                                <Button
                                    color="primary"
                                    onClick={()=>handleDeleteAd(deleteAd.adId)}
                                    disabled={loading}
                                >
                                    YES
                                </Button>
                                {loading && <Spinner size="sm"/>}
                                <Button
                                    color="primary"
                                    onClick={toggleDeleteAdModal}
                                    disabled={loading}
                                >
                                    NO
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={editAdModal}
                    toggle={toggleEditAdModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Edit Ad</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setEditAdModal(false)}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <FormGroup>
                                <Label>
                                    Headline
                                </Label>
                                <Input 
                                    type="text" 
                                    invalid={nameInvalid}
                                    value={editAd.name}
                                    onChange={(e)=> setEditAd({...editAd, name: e.target.value})}
                                />
                                <FormFeedback>
                                    Name required!
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Keyword
                                </Label>
                                <Input 
                                    type="text"
                                    invalid={keywordInvalid}
                                    value={editAd.keyword}
                                    onChange={(e)=> setEditAd({...editAd, keyword: e.target.value})}
                                />
                                <FormFeedback>
                                    Keywords are required!
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Ad Text
                                </Label>
                                <Input 
                                    type="text"
                                    value={editAd.adText}
                                    onChange={(e)=> setEditAd({...editAd, adText: e.target.value})}
                                />
                                {/* <FormFeedback>
                                    Keywords are required!
                                </FormFeedback> */}
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Click Limit
                                </Label>
                                <Input 
                                    type="number"
                                    value={editAd.clickLimit}
                                    onChange={(e)=> setEditAd({...editAd, clickLimit: Number(e.target.value)})}
                                />
                                {/* <FormFeedback>
                                    Keywords are required!
                                </FormFeedback> */}
                            </FormGroup>
                            {editAd.imageLink && <img src={editAd.imageLink} height={'70px'} alt="ad-image" />}
                            <FormGroup>
                                <Label>
                                    Choose an image for Ad
                                </Label>
                                <Input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleEditAdImageUpload}
                                    invalid={imageInvalid}
                                />
                                <FormFeedback>Please Select an image file</FormFeedback>
                                <FormText>{uploadImageText === 'uploading' ? <Spinner size={"sm"}/> : uploadImageText === 'done' ? "Upload Done." : ""}</FormText>
                                <FormFeedback>Please Select an image file</FormFeedback>

                            </FormGroup>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <Button 
                            color="primary"
                            onClick={handleEditAdSubmit}
                            disabled={loading}
                            >
                            {loading ? <Spinner size="sm" /> : "Edit Ad"}
                        </Button>
                    </div>
                </Modal>

            {/* </Container> */}
        </>
        
    );
}

export default AddAds