import OnlyHeader from "components/Headers/OnlyHeader"
import useAuth from "hooks/useAuth"
import { useSelector } from "react-redux";
import { useEffect, useReducer, useState } from "react";
import { FormText, Spinner, Button, Card, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, FormFeedback, CardFooter, UncontrolledTooltip, Alert, CardBody } from "reactstrap";
import { storage } from "configs/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { getAuth } from "firebase/auth";
import {FaPencilAlt} from 'react-icons/fa'
import {FcInfo} from 'react-icons/fc'
import {MdDeleteForever} from 'react-icons/md'
import superApi from "apis/superadminApi";
import api from "apis";
import { toast } from "react-toastify";


const ManageAds = () =>{
    useAuth();

    const {authToken} = useSelector((state)=> state.auth)
    const [searchEmail, setSearchEmail] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)

    const [editAdModal, setEditAdModal] = useState(false)
    const [deleteAdModal, setDeleteAdModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteAd, setDeleteAd] = useState({})
    const [nameInvalid, setNameInvalid] = useState(false)
    const [keywordInvalid, setKeywordInvalid] = useState(false)
    const [imageInvalid, setImageInvalid] = useState(false)
    const [uploadImageText, setUploadImageText] = useState('')

    const [editAd, setEditAd] = useState({
        name: '',
        adText: '',
        keyword: '',
        imageLink: '',
        clickLimit: -1,
        clicks: 0,
        displayImpressions: 0,
    })

    const [ads, setAds] = useState([])

    useEffect(()=>{
        if(searchEmail.trim() !== ''){
            superApi.getIdFromEmail(searchEmail, authToken).then((res)=>{
                var userId = res.data.id
                api.getAds(authToken, userId)
                .then((response)=>{
                    setAds(response.data.Ads)
                    setSearchLoading(false)
                })
                .catch((err)=>{
                    setSearchLoading(false)
                })
            })
            
        }
    },[editAdModal,deleteAdModal])

    const handleSearchSubmit = async() =>{
        setSearchLoading(true)
        var userId = (await superApi.getIdFromEmail(searchEmail, authToken)).data.id
        api.getAds(authToken, userId)
        .then((response)=>{
            setAds(response.data.Ads)
            setSearchLoading(false)
        })
        .catch((err)=>{
            setSearchLoading(false)
        })
    }

    const openEditAdModal = (ad) =>{
        console.log(ad)
        setEditAd({
            ...editAd,
            ... ad,
            keyword: ad.keyword.join(' ')
        })
        setEditAdModal(true)
    }

    const toggleEditAdModal = () =>{
        setEditAd({
            name: '',
            adText: '',
            keyword: '',
            imageLink: ''
        })
        setEditAdModal(!editAdModal)
    }

    const validatorCheckEdit = () =>{
        if(editAd.name.trim() === ''){
            setNameInvalid(true)
        }
        if(editAd.keyword.trim() === ''){
            setKeywordInvalid(true)
        }
        if(editAd.uploadImageText === 'done' ){
            setImageInvalid(false)
        }
        else{
            setImageInvalid(true)
        }

        if(uploadImageText === 'done' || editAd.name.trim() !== '' || editAd.keyword.trim() !== ''){
            return true
        }
        else{
            return false
        }
    }

    const handleEditAdImageUpload = (e) =>{
        setUploadImageText('uploading')
        if(e.target.files[0]){
            const adminId = getAuth().currentUser.uid
            const storageRef = ref(storage, `ads/${adminId}/${e.target.files[0].name}`)
            const uploadTask = uploadBytesResumable(storageRef, e.target.files[0])

            uploadTask.catch((err)=> setUploadImageText("Error in Upload!"))
            uploadTask.then(()=>{
                setUploadImageText('done')
                getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
                    console.log(url)
                    setEditAd({
                        ...editAd,
                        imageLink: url,
                    })
                })
            })
        }
        else{
            setUploadImageText('Please Choose a file!')
        }
    }

    const handleEditAdSubmit = () =>{
        setLoading(true)
        if(validatorCheckEdit()){
            api.editAd({ad: editAd}, authToken)
            .then((res)=>{
                toast.success('Ad Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                setEditAdModal(false)
            })
            .catch((err)=>{
                toast.error('Ad Update Error!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
                setEditAdModal(false)
            })
        }
        else{
            setLoading(false)
        }
        
    }

    const toggleDeleteAdModal = () =>{
        setDeleteAdModal(!deleteAdModal)
    }

    const openDeleteAdModal = (ad) =>{
        setDeleteAd(ad)
        setDeleteAdModal(true)
    }

    const handleDeleteAd = (adId) =>{
        setLoading(true)
        api.deleteAd(adId, authToken)
        .then((resp)=>{
            toast.success('Ad Deleted!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            setDeleteAdModal(false)
        })
        .catch((err)=>{
            toast.error('Ad Delete Error!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            setDeleteAdModal(false)

        })
    }

    return (
        <>
            <OnlyHeader />
            <Container className="mt--7" fluid>
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader>Manage Admin Ads</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="4" md="6" sm="10" >
                                        <FormGroup className="d-flex">
                                            <Input 
                                                placeholder="Enter email to search admins"
                                                style={{width: '70%', marginRight: '10px'}}
                                                value={searchEmail}
                                                onChange={(e)=>setSearchEmail(e.target.value)}
                                            />
                                            <Button
                                                color="primary"
                                                disabled={searchLoading}
                                                onClick={handleSearchSubmit}
                                            >
                                                {searchLoading ? <Spinner size={"sm"}/> : "Search"}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                    ads.length > 0 &&
                                    <Table responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Headline</th>
                                                <th scope="col">Keywords</th>
                                                <th scope="col">Clicks</th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ads.map((ad, index)=>{
                                                return (
                                                    <tr key={`${index+ad.adId}`}>
                                                        <td>{ad.name}</td>
                                                        <td>{ad.keyword.map((key)=> key+" ")}</td>
                                                        <td>{ad.clicks}</td>
                                                        <td>
                                                            <Button
                                                                className="btn-icon btn-2"
                                                                color="primary"
                                                                type="button"
                                                                style={{padding: '3px 5px 3px 5px'}}
                                                                onClick={()=> openEditAdModal(ad)}
                                                            >
                                                                <FaPencilAlt />
                                                            </Button>
                                                            <Button
                                                                className="btn-icon btn-2"
                                                                color="red"
                                                                type="button"
                                                                style={{backgroundColor: '#F44336', padding: '3px 5px 3px 5px'}}
                                                                onClick = {()=> openDeleteAdModal(ad)}
                                                            >
                                                                <MdDeleteForever color="white"/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={deleteAdModal}
                    toggle={toggleDeleteAdModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Delete Ad {deleteAd.name}?</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleDeleteAdModal}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col className="d-flex" style={{justifyContent: "space-around"}}>
                                <Button
                                    color="primary"
                                    onClick={()=>handleDeleteAd(deleteAd.adId)}
                                    disabled={loading}
                                >
                                    YES
                                </Button>
                                {loading && <Spinner size="sm"/>}
                                <Button
                                    color="primary"
                                    onClick={toggleDeleteAdModal}
                                    disabled={loading}
                                >
                                    NO
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    className="modal-dialog-centered"
                    isOpen={editAdModal}
                    toggle={toggleEditAdModal}
                >
                    <div className="modal-header align-items-center">
                        <h4 className="modal-title">Edit Ad</h4>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setEditAdModal(false)}
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <FormGroup>
                                <Label>
                                    Headline
                                </Label>
                                <Input 
                                    type="text" 
                                    invalid={nameInvalid}
                                    value={editAd.name}
                                    onChange={(e)=> setEditAd({...editAd, name: e.target.value})}
                                />
                                <FormFeedback>
                                    Name required!
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Keyword
                                </Label>
                                <Input 
                                    type="text"
                                    invalid={keywordInvalid}
                                    value={editAd.keyword}
                                    onChange={(e)=> setEditAd({...editAd, keyword: e.target.value})}
                                />
                                <FormFeedback>
                                    Keywords are required!
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Ad Text
                                </Label>
                                <Input 
                                    type="text"
                                    value={editAd.adText}
                                    onChange={(e)=> setEditAd({...editAd, adText: e.target.value})}
                                />
                                {/* <FormFeedback>
                                    Keywords are required!
                                </FormFeedback> */}
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Click Limit
                                </Label>
                                <Input 
                                    type="number"
                                    value={editAd.clickLimit}
                                    onChange={(e)=> setEditAd({...editAd, clickLimit: Number(e.target.value)})}
                                />
                                {/* <FormFeedback>
                                    Keywords are required!
                                </FormFeedback> */}
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Clicks
                                </Label>
                                <Input 
                                    type="number"
                                    value={editAd.clicks}
                                    onChange={(e)=> setEditAd({...editAd, clicks: Number(e.target.value)})}
                                />
                                {/* <FormFeedback>
                                    Keywords are required!
                                </FormFeedback> */}
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Display Impressions
                                </Label>
                                <Input 
                                    type="number"
                                    value={editAd.displayImpressions}
                                    onChange={(e)=> setEditAd({...editAd, displayImpressions: Number(e.target.value)})}
                                />
                                {/* <FormFeedback>
                                    Keywords are required!
                                </FormFeedback> */}
                            </FormGroup>
                            {editAd.imageLink && <img src={editAd.imageLink} height={'70px'} alt="ad-image" />}
                            <FormGroup>
                                <Label>
                                    Choose an image for Ad
                                </Label>
                                <Input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleEditAdImageUpload}
                                    invalid={imageInvalid}
                                />
                                <FormFeedback>Please Select an image file</FormFeedback>
                                <FormText>{uploadImageText === 'uploading' ? <Spinner size={"sm"}/> : uploadImageText === 'done' ? "Upload Done." : ""}</FormText>
                                <FormFeedback>Please Select an image file</FormFeedback>

                            </FormGroup>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <Button 
                            color="primary"
                            onClick={handleEditAdSubmit}
                            disabled={loading}
                            >
                            {loading ? <Spinner size="sm" /> : "Edit Ad"}
                        </Button>
                    </div>
                </Modal>
            </Container>
        </>
    )
}

export default ManageAds