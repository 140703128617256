import { Col, Row } from "reactstrap";
import PropTypes from 'prop-types';
import SearchTile from './SearchTile'
import styled from "styled-components";
import { useEffect, useState } from "react";
import publicApi from "apis/adsMatching";
import { useDispatch, useSelector } from "react-redux";
import { setClickedAd } from "redux/adsSlice";
import { useHistory } from "react-router-dom";

const SearchResultList = (props) =>{
    
    const {searchQuery} = useSelector((state)=> state.search)
    const dispatch = useDispatch()
    const navigate = useHistory()

    const [adMatched, setAdMatched] = useState(false)
    const [ads, setAds] = useState([])

    useEffect(()=>{
        publicApi.matchAd(searchQuery)
        .then((response)=>{
            setAds(response.data.ads)
            setAdMatched(true)
        })
        .catch(err=>{
            setAdMatched(false)
        })
    },[])

    const onAdClick = (index) =>{
        dispatch(setClickedAd(ads[index]));
        if(ads[index].adSubpageLink){
            navigate.push(`/${ads[index].adSubpageLink}`,{replace: true})
        }
        else{
            navigate.push('/ad',{replace: true})
        }
        
    }

    return(
        <Row>
            <Col sm="12" md="10">
            {props.resultList?
                props.resultList.map((result,index)=>{
                    if((index === 0 || index === 4 || index === 9)&& window.innerWidth < 576 && adMatched){
                        return <div>
                            <SearchTileWrapper key={result.link}>
                                <SearchTile searchResult={result}/>
                            </SearchTileWrapper>
                            {console.log(`index: ${index}`)}
                            {
                                index === 0 && ads.length > 0 ?
                                    <AdWrapper onClick={()=>onAdClick(0)}>
                                        <div style={{textAlign: 'center'}}>{ads[0].name}</div>
                                        <div style={{height: '230px', overflow: 'hidden', display: "flex", justifyContent: 'center', alignItems: 'center'}}><img src={ads[0].imageLink} height={"230px"} alt=" " /></div>
                                    </AdWrapper>
                                :index === 4 && ads.length > 1 ?
                                    <AdWrapper onClick={()=>onAdClick(1)}>
                                    <div style={{textAlign: 'center'}}>{ads[1].name}</div>
                                    <div style={{height: '230px', overflow: 'hidden', display: "flex", justifyContent: 'center', alignItems: 'center'}}><img src={ads[1].imageLink} height={"230px"} alt=" " /></div>
                                    </AdWrapper>
                                :index === 9 && ads.length > 2 ?
                                    <AdWrapper onClick={()=>onAdClick(2)}>
                                    <div style={{textAlign: 'center'}}>{ads[2].name}</div>
                                    <div style={{height: '230px', overflow: 'hidden', display: "flex", justifyContent: 'center', alignItems: 'center'}}><img src={ads[2].imageLink} height={"230px"} alt=" " /></div>
                                    </AdWrapper>
                                :null
                            }
                        </div>
                    }
                    else{
                        return (
                            <SearchTileWrapper key={result.link}>
                                <SearchTile searchResult={result}/>
                            </SearchTileWrapper>
                        )
                    }
                    
                })
            : 
            <SearchTileWrapper>
                <div style={{marginLeft: '35px'}}><b>Sorry! No Results.</b></div>
            </SearchTileWrapper>
            }
            </Col>

            {/* Ads */}
            <Col sm="0" md="2"
                className="d-flex"
                style={{flexDirection: 'column'}}
            >
                {
                    adMatched && window.innerWidth > 576? 
                    ads.map((ad,index)=>{
                        return (
                            <AdWrapper onClick={()=>onAdClick(index)}>
                                <div style={{textAlign: 'center'}}>{ad.name}</div>
                                <div style={{height: '230px', overflow: 'hidden', display: "flex", justifyContent: 'center', alignItems: 'center'}}><img src={ad.imageLink} height={"230px"} alt=" " /></div>
                            </AdWrapper>
                        )
                    })
                    : null
                }
            </Col>

        </Row>
    )
}

SearchResultList.propTypes = {
    resultList: PropTypes.array.isRequired
}

export default SearchResultList;


const SearchTileWrapper = styled.div.attrs({className: `seatch-tile-wrapper`})`
    padding: 5px;
    margin-top: 25px;
`

const AdWrapper = styled.div.attrs({className: 'ad'})`
    height: 250px;
    width: 100%;
    margin: 5px;
    background-color: #e0e0e0;
    margin-top: 40px;
    cursor: pointer;
    margin-bottom: 40px;
    overflow: hidden;
`

const AdTitle = styled.div.attrs({className: 'ad-title'})`
    text-align: center;
    font-size: 16px;
`

const AdImageWrapper = styled.div.attrs({className: ''})`
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`