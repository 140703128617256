import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { getAuth, sendEmailVerification } from "firebase/auth"
import { toast } from "react-toastify"



const AskEmailVerification = () =>{

    const {authToken} = useSelector((state)=> state.auth)

    useEffect(()=>{

    },[])

    const handleResendEmail = () =>{
        const user = getAuth().currentUser
        sendEmailVerification(user)
        .then((res)=>{
            toast.success('Email Sent!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
        .catch((err)=>{
            toast.error('Unable to send email!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }

    return(
        <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
                <CardBody style={{justifyContent: 'center'}}>
                    <Row>
                        <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <img
                            alt="email"
                            width={'50%'}
                            src={
                                require("../../assets/img/sendEmail.png")
                                .default}
                                
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                            <div>
                                <b>Please Click on the link in the email </b> we just sent you to
                                confirm your email address.
                            </div>
                            <Button
                                onClick={handleResendEmail}
                                color="primary"

                                style={{
                                    width: '50%',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}
                            >
                                Resend Email
                            </Button>
                            <a
                                style={{
                                    cursor: 'pointer'
                                }}
                                href="/auth/login"
                            >
                                Back to Login
                            </a>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
    
}

export default AskEmailVerification