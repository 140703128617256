
// reactstrap components
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import {getAuth, signInWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthToken } from "redux/authSlice";
import {toast} from 'react-toastify';
import superApi from "apis/superadminApi";

const ForgotPassword = () => {

  const navigate = useHistory()
  const dispatch = useDispatch()

  // const []
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false)

  const resetPassword = async() =>{
    setLoading(true)
    const auth = getAuth();
    if(validateEmail()){
        sendPasswordResetEmail(auth, email)
        .then((res)=>{
            console.log(res)
            toast.success('Email Sent!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                setLoading(false)
                setTimeout(()=> navigate.push('/auth/login'), 4000)
        })
        .catch((err)=>{
            toast.error('Email Not Registered!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                setLoading(false)
        })
        ;
        
    }
    

  }

  const validateEmail = () => {
    if (email.trim() === '') {
        // setEmailError('Email is required');
        toast.error('Email is Required!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        return false
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        // setEmailError('Invalid email address');
        toast.error('Invalid Email ',{
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        return false
    } else {
        // setEmailError(null);
        return true
    }
  };

  const handleKeyPress = async(e) =>{
    if(e.key === "Enter"){
      e.preventDefault();
      resetPassword();
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Forgot Password</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button disabled={loading} className="my-4" color="primary" type="button" onClick={resetPassword}>
                  {loading ? <Spinner size="sm"/> : "Send Email"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;
