/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import SuperAdminLayout from "layouts/SuperAdmin.js"
import SearchPage from "views/search/SearchPage";


import {Provider} from 'react-redux'
import store from './redux/store'
import SearchHome from "views/SearchHome";
import {firabase} from './configs/firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdPage from "views/AdPage";
import ResetPassword from "views/auth/resetPassword";
import AboutPage from "views/AboutPage";
import BusinessPage from "views/BusinessPage";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/superadmin" render={(props) => <SuperAdminLayout {...props} />} />
          <Route path="/search" render={(props) => <SearchPage />} />
          <Route path="/about" render={(props)=> <AboutPage />} />
          <Route path="/business" render={(props)=> <BusinessPage />} />
          <Route path="/ad" render={(props) => <AdPage />} />
          <Route path="/:adsubpage" render={(props) => <AdPage />} />
          <Route path="/" render={(props) => <SearchHome />} />
          
          {/* <Redirect from="/" to="/admin/index" /> */}
        </Switch>
        <ToastContainer />
      </BrowserRouter>
      </Provider>
  </React.StrictMode>
  ,
  document.getElementById("root")
);
