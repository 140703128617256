// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyC2k_8biKq4GLGiIruZUZalVmZsExOnnTQ",
    authDomain: "spoofy-385608.firebaseapp.com",
    projectId: "spoofy-385608",
    storageBucket: "spoofy-385608.appspot.com",
    messagingSenderId: "537755997975",
    appId: "1:537755997975:web:d48c553f70b881e99b8952"
  };

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase)
const db = getFirestore(firebase)
const storage = getStorage(firebase)

export {
  firebase,
  analytics,
  auth,
  db,
  storage
}