import superApi from "apis/superadminApi"
import OnlyHeader from "components/Headers/OnlyHeader"
import useAuth from "hooks/useAuth"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Col, Container, Row, Card, CardHeader, CardBody, FormGroup, Label, Input, Button, Form, Spinner, Table } from "reactstrap"
import styled from "styled-components"
import "../../styles/ManageDashboards.css"
import Header from "components/Headers/Header"
import Dashboard from "views/Admin/Dashboard"
import ManageAdsPage from "views/Admin/ManageAdsPage"
import AddAds from "views/Admin/AddAds"
import UserManagment from "views/Admin/UserManagment"



const ManageDashboards = () =>{
    useAuth();
    const {authToken} = useSelector((state)=> state.auth)

    const [searchEmail, setSearchEmail] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)

    const [labels, setLabels] = useState([])
    const [clickData, setClickData] = useState([])
    const [queryResult, setQueryResult] = useState([])

    const [adRate, setAdRate] = useState(0)

    const [users, setUsers] = useState([])
    const [usersLoading, setUsersLoading] = useState(true)

    const [manageDashboardAdminId, setManageDashboardAdminId] = useState('')

    useEffect(()=>{
        superApi.getAllUsers(authToken)
        .then((response)=>{
            setUsers(response.data.users)
            setUsersLoading(false)
        })
        .catch((err)=>{
            setUsersLoading(false)
        })
    },[])

    const handleRowClick = (email) =>{
        setSearchLoading(true)
        setSearchEmail(email)
        superApi.getAdmin6MonthsData(email,authToken)
        .then((response)=>{
            console.log(response.data)
            setClickData(response.data.data.datasets[0].data)
            setLabels(response.data.data.labels)
            setQueryResult(response.data.queryResult)
            console.log("EMail clicked: ", email)
            superApi.getUserByEmail(email,authToken)
            .then((res)=>{
                console.log("user",res.data)
                setAdRate(res.data.data.adRate)
                console.log("UID Selected: ", res.data.data.uid)
                setManageDashboardAdminId(res.data.data.uid)
                setSearchLoading(false)
            })
            .catch((err)=>{
                setSearchLoading(false)
            })
           
        })
        .catch((err)=>{
            setClickData([])
            setLabels([])
            setSearchLoading(false)
        })
    }


    const handleApplyChanges = () =>{
        for(let index=0; index<labels.length;index++){
            let changed = {...queryResult[index], clicks: Number(clickData[index])}
            let queryResultCopy = queryResult
            queryResultCopy[index] = changed
            setQueryResult(queryResultCopy)
        }
        superApi.edit6MonthData({
            adminEmail: searchEmail,
            queryData: queryResult
        },authToken)
        .then((res)=>{
            superApi.editAdRate({
                adminEmail: searchEmail,
                adRate: Number(adRate)
            },authToken)
            .then((res)=>{

            })
            .catch((err)=>{
                toast.error('Error in Updating Ad Rate!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            toast.success('Dashboard Updated!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
        .catch((err)=>{
            toast.error('Error in Updating Dashboard Info!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        })
        console.log(queryResult)
    }

    return(
        <>
            <OnlyHeader />
            <Container className="mt--7" fluid>
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader>Manage Dashboards</CardHeader>
                            <CardBody>

                                {/* <Row>
                                    <Col lg="4" md="6" sm="10" >
                                        <FormGroup className="d-flex">
                                            <Input 
                                                placeholder="Enter email to search admins"
                                                style={{width: '70%', marginRight: '10px'}}
                                                value={searchEmail}
                                                onChange={(e)=>setSearchEmail(e.target.value)}
                                            />
                                            <Button
                                                color="primary"
                                                disabled={searchLoading}
                                                onClick={handleSearchSubmit}
                                            >
                                                {searchLoading ? <Spinner size={"sm"}/> : "Search"}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row> */}

                                {
                                    usersLoading || searchLoading ? <Row className="d-flex" style={{justifyContent: 'center'}}><Spinner /></Row> :
                                    users.length === 0 ? <Row className="d-flex" style={{justifyContent: 'center'}}><h2>No Data!</h2></Row> :
                                    <Table responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Email</th>
                                                {/* <th scope="col"># of Ads</th> */}
                                                <th scope="col">Role</th>
                                                <th scope="col">Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((user)=>{
                                                if(user.role !== "super-admin"){
                                                    return (
                                                        <tr 
                                                            key={user.userId}
                                                            onClick={()=>handleRowClick(user.email)}
                                                            className={`table-row ${user.email === searchEmail ? 'selected-table-row' : 'unselected-table-row'}`}
                                                        >
                                                            <td>{user.email}</td>
                                                            <td>{user.role}</td>
                                                            <td>{user.userCreated}</td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            })}
                                        </tbody>
                                    </Table>
                                }
                                

                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Spacer />
                {
                    manageDashboardAdminId &&
                    <>
                        <Row>
                            <Col>
                            <Card className="shadow">
                                    <CardHeader>Edit Charts</CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg="4" md="6" sm="10">
                                                {
                                                    clickData.map((click, index)=>{
                                                        return (
                                                            <Row style={{marginTop: '5px'}} key={index}>
                                                                <Col>{labels[index]}</Col>
                                                                <Col>
                                                                    <Input
                                                                        value={click}
                                                                        type="number"
                                                                        onChange={(e)=> setClickData([...clickData.slice(0, index), e.target.value, ...clickData.slice(index + 1)])}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                                <hr/>

                                                {clickData.length>0 && <Row style={{marginTop: '5px'}}>
                                                    
                                                    <Col>
                                                    <Label>Ad Rate</Label>
                                                        <Input
                                                            value={adRate}
                                                            type="number"
                                                            onChange={(e)=> setAdRate(e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>}
                                            </Col>

                                            
                                        </Row>

                                        {clickData.length>0 && <Row>
                                            <Col lg="4" md="6" sm="10" className="d-flex justify-content-end">
                                                <Button
                                                    style={{marginTop: '15px'}}
                                                    color="primary"
                                                    onClick={handleApplyChanges}
                                                >
                                                    Apply
                                                </Button>
                                            </Col>
                                        </Row>}
                                    </CardBody>
                                </Card>
                                </Col>
                        </Row>
                        <Spacer />
                        <Header adminId={manageDashboardAdminId} />
                        <Dashboard adminId={manageDashboardAdminId}/>
                        <Spacer />
                        <ManageAdsPage adminId={manageDashboardAdminId}/>
                        <Spacer />
                        <AddAds adminId={manageDashboardAdminId}/>
                        <Spacer />
                        <UserManagment adminId={manageDashboardAdminId}/>
                        <Spacer />
                    </>
                }

                
            </Container>
        </>
    )
}

export default ManageDashboards

const Spacer = styled.div.attrs(``)`
    height: 30px;
`