
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  FormFeedback,
} from "reactstrap";

import {getAuth, createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import { useState, useMemo } from "react";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useDispatch } from "react-redux";
import { createUser } from "redux/authSlice";
import { setAuthToken } from "redux/authSlice";
import { useHistory } from "react-router-dom";
import {toast} from 'react-toastify'
import { setAuthEmail } from "redux/authSlice";
import {BsHouseFill, BsSignpost} from 'react-icons/bs'
import {FaRoad} from 'react-icons/fa'
import {MdOutlineLocationCity} from 'react-icons/md'
import {TiWorld} from 'react-icons/ti'


const Register = () => {

  const dispatch = useDispatch();
  const navigate = useHistory();

  const options = useMemo(() => countryList().getData(), [])

  const [authResponse, setAuthResponse] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const [userDetails, setUserDetails] = useState({
      houseNumber: '',
      street: '',
      zip: '',
      city: '',
      country: ''
  })

  const [invalid, setInvalid] = useState({
      houseNumber: false,
      street: false,
      zip: false,
      city: false,
      country: false,
      name: false
  })

  const handleSubmit = async(event) => {
    console.log("Submit called")
    setAuthResponse('')
    event.preventDefault();
    if(validateEmail() && validatePassword() && validateUserDetails()){
      console.log("Signed up!")
      await signupWithEmailPass()

    }
  };


  


  const signupWithEmailPass = async() =>{
    setLoading(true)
    setAuthResponse('WAIT')
    const auth = getAuth();
    createUserWithEmailAndPassword(auth,email.trim(),password.trim())
      .then(async (userCredential)=>{
          setAuthResponse('')
          await saveToken()
          dispatch(setAuthEmail(userCredential.user.email))
          await sendEmailVerification(userCredential.user)
          await createUserInFirestore(userCredential.user);
          navigate.push('/auth/verifyemail',{replace: true})
          setLoading(false)
      })
      .catch((error)=>{
        if(error.code === "auth/email-already-in-use"){
            toast.error('Email already in use!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
        }
        else{
            setAuthResponse('FAILED')
        }
        setLoading(false)
          
      })
  }

  const createUserInFirestore = async(user) =>{
    const {email, uid, emailVerified} = user
    dispatch(createUser({
      email,
      uid,
      emailVerified,
      name,
      houseNumber: userDetails.houseNumber,
      street: userDetails.street,
      zip: userDetails.zip,
      city: userDetails.city,
      country: userDetails.country
    }))
  }

  const saveToken = async() =>{
    const auth = getAuth();
    var authToken = await auth.currentUser.getIdToken(true);
    dispatch(setAuthToken(authToken))
  }

  const validateEmail = () => {
    if (email.trim() === '') {
        // setEmailError('Email is required');
        toast.error('Email is Required!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        return false
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        // setEmailError('Invalid email address');
        toast.error('Invalid Email ',{
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        return false
    } else {
        // setEmailError(null);
        return true
    }
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/;

      if (password.trim() === '') {
          // setPasswordError('Password is required');
          toast.error('Password Required!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          return false
      } else {
        if(!passwordRegex.test(password.trim())){
          toast.error('Password should be greater than 8 characters, alphanumeric, must contain special charcters and must have upper and lowercase letter!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          return false
        }
        else{
          if(password !== confirmPassword){
            toast.error('Password and Confirm Password should be same!', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            return false
          }
          else{
            return true
          }
          
        }
      }
  };

  const validateUserDetails = () =>{
    var invalidFlag = false
    if(name.trim() === ''){
      setInvalid({...invalid, name: true})
      invalidFlag = true
    }
    else{
      setInvalid({...invalid, name: false})
    }
    if(userDetails.houseNumber.trim() === ''){
      setInvalid({...invalid, houseNumber: true})
      invalidFlag = true
    }
    else{
      setInvalid({...invalid, houseNumber: false})
    }
    if(userDetails.street.trim() === ''){
      setInvalid({...invalid, street: true})
      invalidFlag = true
    }
    else{
      setInvalid({...invalid, street: false})
    }
    if(userDetails.zip.trim() === ''){
      setInvalid({...invalid, zip: true})
      invalidFlag = true
    }
    else{
      setInvalid({...invalid, zip: false})
    }
    if(userDetails.city.trim() === ''){
      setInvalid({...invalid, city: true})
      invalidFlag = true
    }
    else{
      setInvalid({...invalid, city: false})
    }
    if(userDetails.country === ''){
      setInvalid({...invalid, country: true})
      invalidFlag = true
    }
    else{
      setInvalid({...invalid, country: false})
    }
    if(invalidFlag === true){
      return false
    }
    else{
      return true
    }
  }

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign up</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value= {email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e)=>setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <legend>User Details</legend>
                <hr style={{margin: '5px', marginBottom: '15px'}}/>
                <FormGroup>
                  <Input 
                    placeholder="Company Name" 
                    type="text" 
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                    invalid={invalid.name}
                    />
                  <FormFeedback>Name is required!</FormFeedback>
              </FormGroup>
                <Row
                  style={{width: '100%', margin: 'auto', justifyContent: 'space-between'}}
                >
                <FormGroup>
                  {/* <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <BsHouseFill />
                      </InputGroupText>
                    </InputGroupAddon> */}
                    <Input
                      placeholder="House Number"
                      type="text"
                      value={userDetails.houseNumber}
                      onChange={(e)=> setUserDetails({...userDetails, houseNumber: e.target.value})}
                      invalid={invalid.houseNumber}
                    />
                    <FormFeedback>House Number Required!</FormFeedback>
                  {/* </InputGroup> */}
                  
                </FormGroup>
                <FormGroup>
                  {/* <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FaRoad />
                      </InputGroupText>
                    </InputGroupAddon> */}
                    <Input
                      placeholder="Street"
                      type="text"
                      value={userDetails.street}
                      onChange={(e)=> setUserDetails({...userDetails, street: e.target.value})}
                      invalid={invalid.street}
                    />
                    <FormFeedback>Street is Required!</FormFeedback>
                    
                  {/* </InputGroup> */}
                  
                </FormGroup>
                </Row>
                
                
                <Row
                  style={{width: '100%', margin: 'auto', justifyContent: 'space-between'}}
                >
                <FormGroup>
                  {/* <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <MdOutlineLocationCity />
                      </InputGroupText>
                    </InputGroupAddon> */}
                    <Input
                      placeholder="City Name"
                      type="text"
                      value={userDetails.city}
                      onChange={(e)=> setUserDetails({...userDetails, city: e.target.value})}
                      invalid={invalid.city}
                    />
                    <FormFeedback>City is Required!</FormFeedback>
                  {/* </InputGroup> */}
                  
                </FormGroup>
                <FormGroup>
                  {/* <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <BsSignpost />
                      </InputGroupText>
                    </InputGroupAddon> */}
                    <Input
                      placeholder="Post Code"
                      type="text"
                      value={userDetails.zip}
                      onChange={(e)=> setUserDetails({...userDetails, zip: e.target.value})}
                      invalid={invalid.zip}
                    />
                    <FormFeedback>Postcode is required!</FormFeedback>
                  {/* </InputGroup> */}
                  
                </FormGroup>
                </Row>

                <FormGroup className="input-group-alternative">
                  <Select 
                    options={options} 
                    value={userDetails.country} 
                    placeholder={"Select Country"}
                    onChange={(value)=>setUserDetails({...userDetails, country: value})}
                    required={true}
                    validationMessage={"Please select a country!"}
                  />
                  {invalid.country && <div style={{color: 'red'}}>Please Select a country!</div>}
                </FormGroup>
              
              </FormGroup>
              {/* <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div className="text-center">
                <Button disabled={loading} className="mt-4" color="primary" type="button" onClick={handleSubmit}>
                  {loading ? <Spinner size={"sm"} /> : "Register"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
