
import googleConfigs from "../configs/googleConfigs";
import axios from "axios";


const googleSearch = async(query, startIndex = 1, countryCode = 'us') =>{
    
    const response = await axios.get(
        `https://www.googleapis.com/customsearch/v1?q=${query}&start=${startIndex}&gl=${countryCode}&key=${googleConfigs.GOOGLE_API_KEY}&cx=${googleConfigs.SEARCH_ENGINE_ID}`
    )
    return response.data;
}


export default googleSearch