import OnlyHeader from "components/Headers/OnlyHeader"
import { Button, Card, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Label, Row, Spinner } from "reactstrap"
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useEffect, useMemo, useState } from "react"
import api from "apis"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import useAuth from "hooks/useAuth"



const UserManagment = (props) =>{

    useAuth();

    const options = useMemo(() => countryList().getData(), [])
    const {authToken} = useSelector((state)=> state.auth)

    const [userDetails, setUserDetails] = useState({
        houseNumber: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        name: ''
    })
  
    const [nameInvalid, setnameInvalid ] = useState(false)
    const [houseNumberInvalid, sethouseNumberInvalid ] = useState(false)
    const [streetInvalid, setstreetInvalid ] = useState(false)
    const [zipInvalid, setzipInvalid ] = useState(false)
    const [cityInvalid, setcityInvalid ] = useState(false)
    const [countryInvalid, setcountryInvalid ] = useState(false)

    const [loading, setLoading] = useState(false)
    

    useEffect(()=>{
        var propsAdminId = ''
        if(props.adminId){
            propsAdminId = props.adminId
        }

        api.userData(authToken, propsAdminId)
        .then((response)=>{
            setUserDetails({
                ...userDetails,
                ...response.data.user
            })
        })
        .catch((err)=>{
            console.log(err)
        })
    },[props.adminId])


    const handleSubmit = () =>{
        
        if(validateUserDetails()){
            setLoading(true)
            var propsAdminId = ''
            if(props.adminId){
                propsAdminId = props.adminId
            }
            api.editUser({user: userDetails}, authToken, propsAdminId)
            .then((res)=>{
                toast.success('User Details Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)
            })
            .catch((err)=>{
                toast.error('Unable to edit User Details Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false)

            })
        }
    }

    const validateUserDetails = () =>{
        console.log("Validate called")
        var invalidFlag = false
        if(userDetails.name.trim() === ''){
          setnameInvalid(true)
          invalidFlag = true
        }
        else{
          setnameInvalid(false)
        }
        if(userDetails.houseNumber.trim() === ''){
          sethouseNumberInvalid(true)
          invalidFlag = true
        }
        else{
          sethouseNumberInvalid(false)
        }
        if(userDetails.street.trim() === ''){
          setstreetInvalid(true)
          invalidFlag = true
        }
        else{
          setstreetInvalid(false)
        }
        if(userDetails.zip.trim() === ''){
          setzipInvalid(true)
          invalidFlag = true
        }
        else{
          setzipInvalid(false)
        }
        if(userDetails.city.trim() === ''){
          setcityInvalid(true)
          invalidFlag = true
        }
        else{
          setcityInvalid(false)
        }
        if(userDetails.country === ''){
          setcountryInvalid(true)
          invalidFlag = true
        }
        else{
          setcountryInvalid(false)
        }
        if(invalidFlag === true){
          return false
        }
        else{
          return true
        }
    }

    return(
        <>
            {/* <OnlyHeader/>
            <Container className="mt--7" fluid> */}
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader>
                                <h3>User Managment</h3>
                            </CardHeader>
                            <Row>
                                <Col sm="11" md="5" className="m-4">
                                    <FormGroup>
                                        <Label>Company Name</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.name}
                                            onChange={(e)=> setUserDetails({...userDetails, name: e.target.value})}    
                                            invalid={nameInvalid}
                                            required={true}
                                        />
                                        <FormFeedback>Name is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>House Number</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.houseNumber}
                                            onChange={(e)=> setUserDetails({...userDetails, houseNumber: e.target.value})} 
                                            invalid={houseNumberInvalid}
                                        />
                                        <FormFeedback>Street is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Street</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.street}
                                            onChange={(e)=> setUserDetails({...userDetails, street: e.target.value})} 
                                            invalid={streetInvalid}
                                        />
                                        <FormFeedback>Street is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>City Name</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.city}
                                            onChange={(e)=> setUserDetails({...userDetails, city: e.target.value})} 
                                            invalid={cityInvalid}
                                        />
                                        <FormFeedback>City is required!</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Post Code</Label>
                                        <Input 
                                            type="text"
                                            value={userDetails.zip}
                                            onChange={(e)=> setUserDetails({...userDetails, zip: e.target.value})} 
                                            invalid={zipInvalid}
                                        />
                                        <FormFeedback>Post Code is required!</FormFeedback>
                                    </FormGroup>

                                    <FormGroup>
                                        <Select
                                            options={options} 
                                            value={userDetails.country} 
                                            placeholder={"Select Country"}
                                            onChange={(value)=>setUserDetails({...userDetails, country: value})}
                                            required={true}
                                            validationMessage={"Please select a country!"}
                                        />
                                        {countryInvalid && <div style={{color: 'red'}}>Please Select a country!</div>}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="11" md="5" className="m-4">
                                    <Button
                                        color="primary"
                                        disabled={loading}
                                        onClick={handleSubmit}
                                    >
                                        {loading ? <Spinner size="sm"/> : "Apply"}
                                    </Button>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>
            {/* </Container> */}
        </>
    )
}

export default UserManagment