import { Col, InputGroupAddon, Row } from "reactstrap";
import { googleSearchResult,setSearchQuery } from "../../redux/searchSlice"
import { Input, InputGroup, Button, InputGroupText } from 'reactstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";


const SearchBar = () =>{
    const navigate = useHistory();
    const dispatch = useDispatch();
    const {searchQuery} = useSelector((state)=> state.search)


    const searchButtonSubmit = () =>{
        if(searchQuery.trim() !== ""){
            const encodedString = encodeURIComponent(searchQuery)
            navigate.push(`/search?q=${encodedString}`, {replace: true})
            dispatch(googleSearchResult(1));
        }
    }

    const clearSearch = () =>{
        dispatch(setSearchQuery(""))
    }

    const handleKeyPress = (e) =>{
        if (e.key === "Enter"){
            searchButtonSubmit();
        }
    }

    return(
        <Row className="m-2">
            <Col xs="12" sm="12" md="1" 
             style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}
            >
                <a href="/">
                    <img src="spofi_logo.png" width={'100%'} alt={"spofi logo"}/>
                </a>
                
            </Col>
            <Col xs="12" sm="12" md="4" >
                <InputGroup style={{marginLeft: '0px'}}>
                <Input 
                    type="text" 
                    value={searchQuery} 
                    onChange={(e) => dispatch(setSearchQuery(e.target.value))} 
                    onSubmit={searchButtonSubmit}
                    onKeyDown={handleKeyPress}
                    style={{borderRightWidth: '0px', borderRadius: '10rem 0 0 10rem', boxShadow: '-4px 4px 8px rgba(0,0,0,0.2)'}}

                />
                <InputGroupAddon addontype="append">
                    <InputGroupText color="transparent" style={{backgroundColor: 'transparent', boxShadow: '-2px 4px 8px rgba(0,0,0,0.2)', height: '43px', borderRadius: '0', borderLeft: '0', padding: '0'}}>
                        <Button 
                            className="btn-nohover"  
                            color="transparent" onClick={clearSearch}
                            style={{padding: '0.5rem'}}
                        >
                            <FaTimes color="black"/>
                        </Button>
                    </InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addontype="append">
                <InputGroupText color="transparent" style={{backgroundColor: 'transparent', borderRadius: '0 10rem 10rem 0',boxShadow: '4px 4px 8px rgba(0,0,0,0.2)',height: '43px', borderLeft: '0', padding: '0'}}>
                        <Button className="btn-nohover" color="transparent" onClick={searchButtonSubmit}>
                            <FaSearch color="primary"/>
                        </Button>
                    </InputGroupText>
                </InputGroupAddon>
                
                </InputGroup>
            </Col>
            {/* <Col sm="0" md="2" style={{position: 'absolute', right: '0px', display: 'flex', justifyContent: 'flex-end'}}>
            <Button 
                    color="primary" 
                    onClick={()=>{
                        navigate.push('/auth/login',{replace: true})
                    }}
                    style={{minWidth: '90px'}}
                >
                    Sign In
                </Button>
            </Col> */}
        </Row>
    );
}

export default SearchBar