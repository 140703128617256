import axios from 'axios'

import { SERVER_API_URL } from 'configs/urls'

const axiosObj = axios.create({
    baseURL: SERVER_API_URL+"superadmin",
})

const getSuperAdminAuth = (authToken) => axiosObj.get(`/getauth`, {headers: {"AuthToken": authToken}})

const getAllUsers = (authToken) => axiosObj.get(`/users`, {headers: {"AuthToken": authToken}})
const getUserByEmail = (payload, authToken) => axiosObj.get(`/user`, {headers: {"AuthToken": authToken}, params: {adminEmail: payload}})
const deleteUserById = (payload, authToken) => axiosObj.delete(`/user`, {headers: {"AuthToken": authToken}, params: {adminId: payload}})
const getIdFromEmail = (payload, authToken) => axiosObj.get(`/idfromemail`, {headers: {"AuthToken": authToken}, params: {adminEmail: payload}})
const editUser = (payload, authToken) => axiosObj.post(`/edituser`, payload, {headers: {"AuthToken": authToken}})

const getAdmin6MonthsData = (payload, authToken) => axiosObj.get(`/admin6monthdata`, {headers: {"AuthToken": authToken}, params: {adminEmail: payload} })
const edit6MonthData = (payload, authToken) => axiosObj.post(`/edit6monthdata`, payload, {headers: {"AuthToken": authToken}})
const editAdRate = (payload,authToken) => axiosObj.post(`/editadrate`,payload,  {headers: {"AuthToken": authToken}})

const superApi = {
    getSuperAdminAuth,

    getAllUsers,
    getUserByEmail,
    deleteUserById,
    getIdFromEmail,
    editUser,

    getAdmin6MonthsData,
    edit6MonthData,
    
    editAdRate
}

export default superApi