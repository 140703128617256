import { Col, Row } from "reactstrap";
import styled from "styled-components";


const SearchTile = (props) =>{
    return(
        <Row>
            <Col xs="0" sm="0" md="1" lg="1"/> 
            <Col xs="12" sm="12" md="6" lg="6">
                <div onClick={()=>window.location.href = props.searchResult.link}>
                <Title><b style={{overflowWrap: 'break-word'}}>{props.searchResult.title}</b></Title>
                <Link style={{overflowWrap: 'break-word'}}>{props.searchResult.link}</Link>
                <div style={{overflowWrap: 'break-word'}}>{props.searchResult.snippet}</div>
                </div>
            </Col>
        </Row>
        
    );
}

export default SearchTile


const Title = styled.div.attrs({className: 'search-title'})`
    font-size: 20px;
    font-weight: 400;
    // color: #8ab4f8;
    color: #1a0dab;
    font-family: arial, sans-serif;
    cursor: pointer;
`

const Link = styled.div.attrs({className: 'search-link'})`
    font-size: 13px;
    margin-bottom: 5px;
    cursor: pointer;
`