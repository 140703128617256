const { createSlice } = require("@reduxjs/toolkit");


const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        countryCode: {
            label: "United States", 
            value: "US"
        }
    },
    reducers: {
        setCountryCode: (state, action)=>{
            state.countryCode = action.payload
        }
    }
})

export const {setCountryCode} = settingsSlice.actions
export default settingsSlice.reducer