import api from "apis";
import OnlyHeader from "components/Headers/OnlyHeader";
import { getAuth } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "configs/firebase";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, Row, Col, Container, FormGroup, Label, Input, FormFeedback, Button, FormText, Spinner } from "reactstrap";
import { toast } from "react-toastify";


const ManageAdsPage = (props) =>{
    useAuth();
    const {authToken} = useSelector((state)=> state.auth)

    const [adLinkError, setAdLinkError] = useState('')
    const [adLinkInvalid, setAdLinkInvalid] = useState(false)
    const [adLink, setAdLink] = useState('')

    const [webpageLink, setWebpageLink] = useState('')
    const [webpageLinkInvalid, setWebpageLinkInvalid] = useState(false)

    const [uploadImageText, setUploadImageText] = useState('')
    const [logoUrl, setLogoUrl] = useState('')

    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        var propsAdminId = ''
        if(props.adminId){
            propsAdminId = props.adminId
        }

        api.userData(authToken, propsAdminId)
        .then((response)=>{
            setAdLink(response.data.user.adSubpageLink)
            setWebpageLink(response.data.user.webpageLink)
            setLogoUrl(response.data.user.logoLink.trim())
            setUserData(response.data.user)
        })
        .catch((err)=>{
            console.log(err)
        })
    },[props.adminId])

    const handleImageUpload = (e) =>{
        setUploadImageText('uploading')


        if(e.target.files[0]){

            var adminId = ''
            if(props.adminId){
                adminId = props.adminId
            }
            else{
                adminId = getAuth().currentUser.uid
            }

            
            const storageRef = ref(storage, `ads/${adminId}/${e.target.files[0].name}`)
            const uploadTask = uploadBytesResumable(storageRef, e.target.files[0])

            uploadTask.catch((err)=> setUploadImageText("Error in Upload!"))
            uploadTask.then(()=>{
                setUploadImageText('done')
                getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
                    setLogoUrl(url)
                })
            })
        }
        else{
            setUploadImageText('Please Choose a file!')
        }
    }

    const handleSubmit = async() =>{
        setLoading(true)

        var propsAdminId = ''
        if(props.adminId){
            propsAdminId = props.adminId
        }

        var adpageValid = await validateAdPage()
        var webpageValid = validateWebpage()
        if(webpageValid && adpageValid){
            api.editUser({
                user:{
                    ...userData,
                    logoLink: logoUrl,
                    adSubpageLink: adLink.toLowerCase().trim(),
                    webpageLink: webpageLink.trim()
                }
            }, authToken, propsAdminId)
            .then((response)=>{
                setLoading(false)
                setAdLinkInvalid(false)
                setWebpageLinkInvalid(false)
                toast.success('Ad Page Updated!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch((err)=>{
                setLoading(false)
                toast.error('Error in User Update!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
        }
        else{
            setLoading(false)
        }
        
    }

    const validateWebpage = () =>{
        if(webpageLink.trim() === ""){
            setWebpageLinkInvalid(true)
            return false
        }
        else{
            console.log("vv")
            setWebpageLinkInvalid(false)
            return true
        }
    }


    const validateAdPage = async() =>{
        if(adLink.trim() === ""){
            setAdLinkInvalid(true)
            setAdLinkError("Ad subpage can't be Empty!")
            return false
        }
        else{
            var propsAdminId = ''
            if(props.adminId){
                propsAdminId = props.adminId
            }

            var response = await api.adSubpageUnique({adSubpageLink: adLink}, authToken, propsAdminId)
            if(response.data.unique){
                return true
            }
            else{
                setAdLinkInvalid(true)
                setAdLinkError("Your desired page link is already in use! Please try another")
                return false
            }
        }
    }   


    return (
        <>
            {/* <OnlyHeader />
            <Container className="mt--7" fluid> */}
                <Row>
                    <Col>
                        <Card className="shadow">
                            <CardHeader>
                                <h3>Manage Ads Page</h3>
                            </CardHeader>
                            <Row>
                                <Col sm="11" md="7" className="m-4">
                                    <FormGroup>
                                        <Label>Ads Page Link</Label>
                                        <Input
                                            type="text"
                                            invalid={adLinkInvalid}
                                            value={adLink}
                                            onChange={(e)=>setAdLink(e.target.value)}
                                        />
                                        <FormFeedback>
                                            {adLinkError}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Link To Webpage</Label>
                                        <Input
                                            type="text"
                                            invalid={webpageLinkInvalid}
                                            value={webpageLink}
                                            onChange={(e)=> setWebpageLink(e.target.value)}
                                        />
                                        <FormFeedback>
                                            You need to provide a link to webpage!
                                        </FormFeedback>
                                    </FormGroup>
                                    {logoUrl && <img src={logoUrl} alt="logo" width={"200px"}/>}
                                    <FormGroup>
                                        <Label>Ad Page Logo</Label>
                                        <Input  
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                        <FormText>{uploadImageText === 'uploading' ? <Spinner size={"sm"}/> : uploadImageText === 'done' ? "Upload Done." : ""}</FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="11" md="7" className="m-4">
                                    <Button 
                                        color="primary"
                                        disabled={loading}
                                        onClick={handleSubmit}
                                    >
                                        {loading ? <Spinner size="sm"/> : "Apply"}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            {/* </Container> */}
        </>
    );
}

export default ManageAdsPage