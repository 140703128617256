import axios from 'axios'

import { PUBLIC_SERVER_API_URL } from 'configs/urls'

const axiosObj = axios.create({
    baseURL: PUBLIC_SERVER_API_URL,
})


const matchAd = (query) => axiosObj.get(`/matchad?q=${encodeURIComponent(query)}`)
const incrementAdClick = (adId) => axiosObj.get(`/click?adId=${encodeURIComponent(adId)}`)

const publicApi = {
    matchAd,
    incrementAdClick
}

export default publicApi